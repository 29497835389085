<template>
  <div class="bg" @click="closeImg">
    <div class="img">
      <img :src="img" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.init();
  },
  props: {
    img: {
      type: String,
      default: "",
    },
  },
  methods: {
    async init() {
      this.$store.dispatch("common/setLoading", true);
      await this.$loadImage(this.img);
      this.$store.dispatch("common/setLoading", false);
    },
    closeImg() {
      this.$store.dispatch("common/setLoading", false);
      this.$emit("imgOpen");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  .img {
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    img {
      width: 100%;
    }
  }
}
</style>
