<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰 포인트 인출 신청하기
      <router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_20">
      <div class="top">
        <ul>
          <li>
            등록되어 있는 계좌번호가 잘못되어, 다른 계좌로 램 보상비가 송금된
            경우, 그 책임은 잘못된 계좌 정보를 입력한 회원에게 있으며 회사는
            이에 대해 책임을 지지 않습니다.
          </li>
          <li>
            리뷰어 회원은 아래 계좌 정보를 다시 확인해주시기 바랍니다. 계좌
            정보가 잘못된 경우 본인의 계좌 인증을 다시 하셔야 합니다.
          </li>
          <li>
            현금인출은 500만 포인트(5만원)부터 가능하며 인출 단위는 100만
            포인트(1만원)입니다. (단위환산 : 100포인트=1원)
          </li>
          <li>
            회사가 리뷰어 계좌로 보상비를 이체할 때 드는 송금 수수료는 리뷰어
            본인 부담입니다.(국민은행 제외)
          </li>
          <li>
            리뷰 보상비는 신청일 기준 다음 주 수요일에 본인 계좌의 통장으로
            지급됩니다.
          </li>
          <li>지급일이 공휴일일 경우 다음 영업일에 지급됩니다.</li>
          <li>
            운영 정책을 위반해 수익이 발생하는 경우 지급이 거절 될 수 있습니다.
          </li>
        </ul>
      </div>
      <div class="sect_info">
        <div class="box">
          <div class="left">현재 내 리뷰 보상 포인트</div>
          <div class="right">총 {{ won(totalPoint) }} P</div>
        </div>
        <div class="box">
          <div class="left">인출 가능한 포인트</div>
          <div class="right">총 {{ won(useablePoint) }} P</div>
        </div>
      </div>
      <div class="sect_apply">
        <ul class="price">
          <li>인출 신청 금액</li>
          <li>
            <input type="text" name="" @keyup="check" v-model="applyPoint" />
          </li>
          <li>원</li>
        </ul>
        <p class="error-txt">{{ errorMsg }}</p>
        <div class="table">
          <table summary="">
            <colgroup>
              <col width="65px" />
              <col width="*" />
              <col width="65px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td class="tit"><span>예금주</span></td>
                <td class="txt">
                  {{ myInfo.user_name }}
                </td>
              </tr>
              <tr>
                <td class="tit" colspan="4">
                  <span @click="imgOpen">통장사본보기</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="check" v-if="account">
          <label
            ><input type="checkbox" name="" v-model="agree" />위 내용을 모두
            확인했습니다.</label
          ><router-link to="" @click.native="changeAccount"
            >입금 계좌 수정하기</router-link
          >
        </div>
      </div>
      <div class="sect_modify" v-if="!account">
        <div class="info">
          <h5>본인의 계좌 인증</h5>
          <div class="bottom">
            <div><input type="file" ref="upload_Img" name="" /></div>
            <p>
              [파일 선택]을 눌러 파일함에서 ‘계좌번호와 본인의 이름’이 보이는
              통장 사본을 제출해주세요.
            </p>
          </div>
        </div>
        <div class="check">
          <label>
            <input type="checkbox" name="" />위 내용을 모두 확인했습니다.
          </label>
          <router-link to="" @click.native="changeAccount"
            >입금 계좌 수정취소</router-link
          >
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn yellow" v-if="account">
      <div>
        <router-link to="" @click.native="submitApply"
          >인출 신청하기</router-link
        >
      </div>
    </div>
    <div class="rn_my_box_btn yellow" v-else>
      <div>
        <router-link to="" @click.native="submitPassBookImg"
          >입금 계좌 수정 신청</router-link
        >
      </div>
    </div>
    <ImageModal :img="passbookImg" v-if="isOpen" @imgOpen="imgOpen" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ImageModal from "@/components/util/ImageModal";
export default {
  data() {
    return {
      account: true,
      isOpen: false,
      applyPoint: 0,
      RAMM_DEFAULT_POINT: 10000,
      RAMM_MIN_POINT: 50000,
      error: false,
      errorMsg: "",
      agree: false,
      image: null,
    };
  },
  components: {
    ImageModal,
  },
  created() {
    this.getReviewPointInfo();
  },
  computed: {
    ...mapState("mypage", [
      "checkAuth",
      "myInfo",
      "msg",
      "result",
      "totalPoint",
      "useablePoint",
      "passbookImg",
    ]),
  },
  methods: {
    changeAccount() {
      this.account = !this.account;
    },
    check() {
      if (!this.applyPoint) {
        this.errorMsg = `인출 신청 금액을 입력해주세요.`;
        this.error = true;
        return false;
      }
      this.applyPoint = this.applyPoint.replace(/[^0-9]/g, "");

      if (parseInt(this.applyPoint) < this.RAMM_DEFAULT_POINT) {
        this.errorMsg = `램 보상 포인트는 최소 ${this.won(
          this.RAMM_DEFAULT_POINT
        )}P 이상 부터 인출 신청이 가능합니다`;
        this.error = true;
        return false;
      }
      if (parseInt(this.applyPoint) % this.RAMM_DEFAULT_POINT !== 0) {
        this.errorMsg = `램 보상 포인트 ${this.won(
          this.RAMM_DEFAULT_POINT
        )}p 단위로 인출 신청이 가능합니다`;
        this.error = true;
        return false;
      }
      if (parseInt(this.useablePoint) < this.RAMM_MIN_POINT) {
        this.errorMsg = `보유한 램 보상 포인트가 ${this.won(
          this.RAMM_MIN_POINT
        )}P 이상 있어야 인출 신청이 가능합니다.`;
        this.error = true;
        return false;
      }
      if (parseInt(this.applyPoint) > parseInt(this.useablePoint)) {
        this.errorMsg = `인출가능한 램 보상 포인트 보다 많은 포인트 입니다.`;
        this.error = true;
        return false;
      }
      this.errorMsg = "";
      this.error = false;
      return true;
    },
    async getReviewPointInfo() {
      await this.$store.dispatch("mypage/getReviewPointInfo");
    },
    async submitApply() {
      if (!this.agree) {
        this.$toast.default("위 내용에 확인 해주세요.");
        return false;
      }
      if (this.check()) {
        await this.$store.dispatch("mypage/submitApply", {
          point: this.applyPoint,
        });
        this.$toast.default(this.msg);
        this.agree = false;
      }
    },
    submitPassBookImg() {
      this.image = this.$refs.upload_Img.files[0];
      const allow_ext = ["jpg", "png", "jpeg", "gif"];
      if (!this.image) {
        this.$toast.default("통장사본 이미지를 첨부 해주세요.");
        return false;
      }
      this.$confirm("통장사본 이미지를 수정 하시겠습니까?")
        .then(async () => {
          let ext = this.image.name.split(".").pop().toLowerCase();
          const ext_check = allow_ext.some((item) => item === ext);
          if (!ext_check) {
            this.$toast.default("허용되지 않는 파일 입니다.");
            this.$refs.upload_Img.value = null;
            return false;
          }
          let formData = new FormData();
          formData.append("filedata", this.image);
          await this.$store.dispatch("mypage/submitPassBookImg", formData);
          this.$refs.upload_Img.value = null;
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    won(str) {
      return this.$won(str);
    },
    imgOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.rn_my_box_20 .sect_apply .price {
  margin-bottom: 0px !important;
}
.error-txt {
  padding: 7px 0;
}
</style>
